.sec2-container {
    text-align: center;
    padding: 40px;
    background-color: #000000;
    font-family: Arial, sans-serif;
    color: rgb(0, 0, 0);
  }
  
  .sec2-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 30px;
    color: rgb(248, 245, 245);
  }
  
  .sec2-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    .sec2-grid {
      justify-content: center; /* Align pillars in the center */
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust column size for mobile */
      padding: 20px; /* Add padding for better mobile spacing */
    }

    .sec2-card {
      margin: 0 auto; /* Center each card horizontally */
    }
  }

  
  .sec2-card-container {
    position: relative;
  }
  .sec2-card {
    border: 1px solid #ffffff !important; /* Black border for all cards */
    background-color: #000000 !important;
    color: #ffffff !important;
    width: 250px; /* Fixed width for all cards */
    height: 150px; /* Fixed height for all cards */
    display: flex; /* Align content inside the card */
    flex-direction: column; /* Stack text vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    overflow: hidden;
    padding: 50px;
  }

  .sec2-card:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }
  
  .sec2-card h6 {
    margin: 0;
    margin-bottom: 10px;
  }
  
  .arrowsec {
    font-size: 18px;
    color: #fefefe;
  }
  
  .sec2-dropdown {
    position: absolute;
    top: 100%; /* Dropdown at the bottom of the card */
    left: 0;
    width: 100%;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    text-align: left;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  
  .sec2-dropdown p {
    margin: 0;
    font-size: 14px;
    color: #333;
  }
  
  .sec2pillername{
    font-weight: bold;
  }