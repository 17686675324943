/* Background and Section Styling */
.glb-solutions-section {
    height:80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #00923f, #070054);
    color: white;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
  }
  
  /* Container Layout */
  .glb-solutions-container {
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  /* Left Content */
  .glb-solutions-left {
    flex: 1;
    min-width: 300px;
  }
  
  .glb-solutions-title {
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1.2;
    color: white;
  }
  
  .glb-solutions-title span {
    color: #000000;
  }
  
  /* Right Content */
  .glb-solutions-right {
    flex: 1;
    min-width: 300px;
  }
  
  .glb-solutions-description {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 30px;
    color: white;
  }
  
  /* Button */

.glb-solutions-button {
    background-color: transparent;
    color: white;
    border: 2px solid white;
    padding: 12px 50px; /* Increased padding for width */
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    width: 250px; /* Explicitly setting width */
    text-align: center;
    transition: all 0.3s ease-in-out;
  }
  
  .glb-solutions-button:hover {
    background-color: white;
    color: #053701;
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .glb-solutions-container {
      flex-direction: column;
      text-align: center;
    }
  
    .glb-solutions-left,
    .glb-solutions-right {
      flex: none;
    }
  
    .glb-solutions-title {
      font-size: 2.5rem;
    }
  
    .glb-solutions-description {
      font-size: 1rem;
    }
  
    .glb-solutions-button {
      padding: 10px 20px;
    }
  }
  