.vendorTitel{
    text-align: center;
}

.headingrow{
    padding-top: 5%;
}

.modelHeader {
    background-color: #01066B;
    color: white;
}

.websiteButton {
    width: 30% !important;
}

.btnBody{
    text-align: center;
}

.custom-image-height {
    width: 230px !important;  /* Adjust the height value as needed */
     
}
