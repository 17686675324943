/*--------------------------------------------------------------
# Exclusive Section with Updated Colors
--------------------------------------------------------------*/
.glb-exclusive-section {
    background: linear-gradient(135deg, #003030, #172e2e, #0d9191); /* Colors inspired by the image */
    color: #ffffff;
    padding: 30px 1px;
    text-align: center;
}

.glb-exclusive-heading {
    font-size: 70px;
    font-weight: 700;
    color: #00ccff; /* Light blue matching the image */
    margin-bottom: 20px;
}

.glb-exclusive-highlight {
    font-size: 80px;
    font-weight: 900;
    background: linear-gradient(90deg, #01ad01, #00ff99); /* Green gradient inspired by the image */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 40px;
}

.glb-exclusive-description {
    font-size: 22px;
    font-weight: 860;
    line-height: 1.6;
    max-width: 900px;
    margin: 0 auto;
    color: #e0f7fa; /* Light teal matching the background */
}

.glb-highlight {
    color: #00ffcc; /* Highlight in teal-green */
    font-weight: 700;
}

.glb-glbiconRow {
    margin-top: 30px;
}

.glb-iconCol {
    margin: 10px 0;
}

.glb-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.glb-iconMob {
    width: 90px;
    height: 90px;
}

.glb-iconText {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
}

.glb-textRow {
    margin-top: 10px;
}

.glb-statistics-note {
    font-size: 14px;
    font-style: italic;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 15px;
}

@media (max-width: 768px) {
    .glb-exclusive-heading {
        font-size: 46px;
    }

    .glb-exclusive-highlight {
        font-size: 38px;
    }

    .glb-exclusive-description {
        font-size: 16px;
        line-height: 1.6;
    }

    .glb-iconMob {
        width: 50px;
        height: 50px;
    }

    .glb-iconText {
        font-size: 14px;
    }
}
