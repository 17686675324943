/* Outer White Background Container */
.reg-sec2-outer {
    background-color: #ffffff; /* White background color */
    padding: 20px;
    display: flex;
    flex-direction: column; /* Stack cards vertically */
    gap: 20px; /* Space between cards */
    align-items: center;
  }
  
  /* Container Styles */
  .reg-sec2-container {
    background-color: #002f6c; /* Deep blue background */
    color: #ffffff; /* White text color */
    padding: 40px;
    width: 80%; /* Set container width */
    border-radius: 20px; /* Rounded corners */
  }
  
  /* Header Styles */
  .reg-sec2-header {
    margin-bottom: 20px;
  }
  
  .reg-sec2-header h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #00aaff; /* Light blue text color */
  }
  
  /* Content Styles */
  .reg-sec2-content {
    display: flex; /* Flex layout for horizontal alignment */
    gap: 30px; /* Space between countries */
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
  }
  
  .reg-sec2-location {
    flex: 1; /* Equal width for all countries */
    min-width: 270px;
    background-color: #003366; /* Slightly different shade for countries */
    padding: 20px;
    border-radius: 10px; /* Rounded corners for country cards */
  }
  
  .reg-sec2-location h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .reg-sec2-location ul {
    list-style: none;
    padding: 0;
    line-height: 1.8;
  }
  
  .reg-sec2-location ul li {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
  }
  
  .reg-sec2-location ul li i {
    color: #00aaff; /* Light blue icon color */
  }
  
  /* Clickable Contact Us Link */
  .contact-us-link {
    color: #00aaff;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .contact-us-link:hover {
    color: #0056b3; /* Darker blue on hover */
  }
  .sec2-card {
    border: 1px solid #000; /* Black border for all cards */
    background-color: #fff;
    color: #000;
    width: 250px; /* Fixed width for all cards */
    height: 150px; /* Fixed height for all cards */
    display: flex; /* Align content inside the card */
    flex-direction: column; /* Stack text vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
  }

  @media (max-width: 427px) {
    .reg-sec2-container {
      width: 100%; /* Increase the width for smaller screens */
    }
  }