
/* Nav Menu Colors */
:root {
  --nav-color: #ffffff;
  --nav-hover-color: #00ff59;
  --nav-dropdown-color: #212529;
  --nav-dropdown-hover-color: #00ff59;
  --nav-dropdown-background-color: #ffffff;
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

.rndr{
  border-radius: 20px;
}
a {
  color: var(--accent-color);
  text-decoration: none !important;
  transition: 0.3s;
}

a:hover {
  color: rgba(var(--accent-color-rgb), 0.75);
  text-decoration: none;
}
/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.header {
  color: var(--default-color);
  background-color: rgba(20, 2, 93, 0.67);
  padding: 15px 0;
  transition: all 0.5s;
  z-index: 997;
  box-shadow: 0px 0 15px rgba(var(--default-color-rgb), 0.1);
  width: 95%;
  margin-top: 0.5%;
  margin-left: 2.5%;
  border-radius: 50px;
  height: 65px;
  font-weight: bolder;

}

.header .logo {
  line-height: 1;
}

.header .logo img {
  max-height: 36px;
  margin-right: 8px;
}

.header .logo h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 400;
  letter-spacing: 2px;
  color: var(--heading-color);
}

.header .btn-getstarted,
.header .btn-getstarted:focus {
  color: var(--contrast-color);
  background:#16a800;
  font-size: 14px;
  padding: 8px 25px;
  margin: 0 0 0 30px;
  border-radius: 50px;
  transition: 0.3s;
}

.header .btn-getstarted:hover,
.header .btn-getstarted:focus:hover {
  color: var(--contrast-color);
  background: #0004ff;
}

@media (max-width: 1200px) {
  .header .logo {
    order: 1;
  }

  .header .btn-getstarted {
    order: 2;
    margin: 0 15px 0 0;
    padding: 6px 15px;
  }

  .header .navmenu {
    order: 3;
  }
}


/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Navmenu - Desktop */
@media (min-width: 1200px) {
.navmenu {
  padding: 0;
}


.navmenu ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navmenu li {
  position: relative;
}

.navmenu a,
.navmenu a:focus {
  color: var(--nav-color);
  padding: 18px 15px;
  font-size: 16px;
  font-family: var(--nav-font);
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  transition: 0.3s;
}

.navmenu a i,
.navmenu a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
  transition: 0.3s;
}

.navmenu li:last-child a {
  padding-right: 0;
}

.navmenu li:hover>a,
.navmenu .active,
.navmenu .active:focus {
  color: #00ff59;
  transform: scale(1.10);
  
}
.navmenu .li:active{
    transform: scale(0.95);
}

.navmenu .dropdown ul {
  margin: 0;
  padding: 10px 0;
  background: var(--nav-dropdown-background-color);
  display: block;
  position: absolute;
  visibility: hidden;
  left: 14px;
  top: 130%;
  opacity: 0;
  transition: 0.3s;
  border-radius: 4px;
  z-index: 99;
  box-shadow: 0px 0px 30px rgba(var(--default-color-rgb), 0.15);
}

.navmenu .dropdown ul li {
  min-width: 200px;
}

.navmenu .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  color: var(--nav-dropdown-color);
}

.navmenu .dropdown ul a i {
  font-size: 12px;
}

.navmenu .dropdown ul a:hover,
.navmenu .dropdown ul .active:hover,
.navmenu .dropdown ul li:hover>a {
  color: var(--nav-dropdown-hover-color);
}

.navmenu .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navmenu .dropdown .dropdown ul {
  top: 0;
  left: -90%;
  visibility: hidden;
}

.navmenu .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: -100%;
  visibility: visible;
}
}

/* Navmenu - Mobile */
@media (max-width: 1199px) {
.mobile-nav-toggle {
  color: var(--nav-color);
  font-size: 28px;
  line-height: 0;
  margin-right: 10px;
  cursor: pointer;
  transition: color 0.3s;
}



.countries{
  margin-left: 5% ;
  padding-top: 10px;
}

.navmenu {
  padding: 0;
  z-index: 9997;
}

.navmenu ul {
  display: none;
  position: absolute;
  inset: 60px 20px 20px 20px;
  padding: 10px 0;
  margin: 0;
  border-radius: 6px;
  background-color: var(--nav-dropdown-background-color);
  border: 1px solid rgba(var(--default-color-rgb), 0.1);
  box-shadow: 0px 0px 30px rgba(var(--default-color-rgb), 0.1);
  overflow-y: auto;
  transition: 0.3s;
  z-index: 9998;
}

.navmenu a,
.navmenu a:focus {
  color: var(--nav-dropdown-color);
  padding: 10px 20px;
  font-family: var(--nav-font);
  font-size: 17px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  transition: 0.3s;
}

.navmenu a i,
.navmenu a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.3s;
  background-color: rgba(var(--accent-color-rgb), 0.1);
}

.navmenu a i:hover,
.navmenu a:focus i:hover {
  background-color: var(--accent-color);
  color: var(--contrast-color);
}

.navmenu a:hover,
.navmenu .active,
.navmenu .active:focus {
  color: var(--nav-dropdown-hover-color);
}

.navmenu .active i,
.navmenu .active:focus i {
  background-color: var(--accent-color);
  color: var(--contrast-color);
  transform: rotate(180deg);
}

.navmenu .dropdown ul {
  position: static;
  display: none;
  z-index: 99;
  padding: 10px 0;
  margin: 10px 20px;
  background-color: var(--nav-dropdown-background-color);
  transition: all 0.5s ease-in-out;
}

.navmenu .dropdown ul ul {
  background-color: rgba(33, 37, 41, 0.1);
}

.navmenu .dropdown>.dropdown-active {
  display: block;
  background-color: rgba(33, 37, 41, 0.03);
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav-toggle {
  color: #fff;
  position: absolute;
  font-size: 32px;
  top: 15px;
  right: 15px;
  margin-right: 0;
  z-index: 9999;
}

.mobile-nav-active .navmenu {
  position: fixed;
  overflow: hidden;
  inset: 0;
  background: rgba(33, 37, 41, 0.8);
  transition: 0.3s;
}

.mobile-nav-active .navmenu>ul {
  display: block;
}
}
.no-bullets {
  list-style-type: none;
}

.arrow1{
  margin-right: 1px !important;
}

/* Add this to your CSS */

.scrollable-menu {
  height: auto;
  max-height: 360px; 
  overflow-y: auto; 
  overflow-x: hidden;
}


.scrollable-menu li {
  display: block;
}

.scrollable-menu li a {
  padding: 10px;
}

.scrollable-menu li:hover {
  background-color: #f5f5f5;
}

.flag{
  width: 20%;
}

.flagNavbar{
  margin-left: 1px;
}

.arrow{
  margin-left: -27px !important;
}

.arrow1{
 margin-right: -20px;
}