/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.heroglb {
    position: relative;
    width: 100%;
    height: 88vh; /* Full viewport height */
    overflow: hidden; /* Prevent scrollbars caused by video overflow */
  }
  
  .hero-videoglb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the entire section */
    z-index: -1; /* Places the video behind other content */
  }
  
  .content-wrapperglb {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 1;
    width: 100%;
  }
  
  .image-containerglb {
    margin-bottom: 2rem;
  }
  
  .connex-imageglb {
    max-width: 100%;
    height: auto;
  }
  
  .text-containerglb {
    flex-direction: column;
  }
  
  /* Fade-down animation for main text */
  .main-textglb {
    font-size: 3.1rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
    opacity: 0; /* Start with invisible text */
    transform: translateY(-20px); /* Start slightly above */
    animation: fadeDown 1.5s ease-out forwards;
  }
  
  @keyframes fadeDown {
    to {
      opacity: 1; /* Fade in */
      transform: translateY(0); /* Move to original position */
    }
  }
  
  /* Letter-by-letter animation for additional text */
  .additional-textglb {
    font-size: 2.1rem;
    line-height: 1.6;
    overflow: hidden; /* Hide overflow */
    white-space: pre-wrap; /* Preserve spaces and line breaks */
  }
  
  .additional-textglb p {
    margin: 0.5rem 0;
    display: inline-block; /* Ensure letters are treated as inline */
    overflow: hidden;
  }
  
  .additional-textglb p span {
    display: inline-block;
    opacity: 0; /* Start with invisible letters */
    transform: translateY(-20px); /* Start slightly above */
    animation: fadeDownLetter 0.3s ease-out forwards; /* Faster animation */
  }
  
  /* Delay each letter's animation */
  .additional-textglb p span {
    animation-delay: calc(0.05s * var(--index)); /* Faster delay based on letter position */
  }
  
  @keyframes fadeDownLetter {
    to {
      opacity: 1; /* Fade in */
      transform: translateY(0); /* Move to original position */
    }
  }
  
  /* Responsive adjustments for additional-textglb */
  @media (max-width: 1200px) {
    .additional-textglb {
      font-size: 1.8rem;
      line-height: 1.5;
    }
  }
  
  @media (max-width: 992px) {
    .additional-textglb {
      font-size: 1.6rem;
      line-height: 1.4;
    }
  }
  
  @media (max-width: 768px) {
    .additional-textglb {
      font-size: 1.4rem;
      line-height: 1.3;
    }
  }
  
  @media (max-width: 576px) {
    .additional-textglb {
      font-size: 1.2rem;
      line-height: 1.2;
    }
  }
  
  @media (max-width: 480px) {
    .additional-textglb {
      font-size: 1rem;
      line-height: 1.1;
    }
  }
  
  /* Animation for the #EMPOWERINGTechnology text */
  .qwer {
    font-size: 6.1rem;
    background: linear-gradient(90deg, #adfeff, #dfe9ed, #0fb899);
    background-size: 200% auto;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradientAnimation 3s linear infinite, fadeIn 1.5s ease-out;
  }
  
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive adjustments for #EMPOWERINGTechnology text */
  @media (max-width: 1200px) {
    .qwer {
      font-size: 5rem;
    }
  }
  
  @media (max-width: 992px) {
    .qwer {
      font-size: 4rem;
    }
  }
  
  @media (max-width: 768px) {
    .qwer {
      font-size: 3rem;
    }
  }
  
  @media (max-width: 576px) {
    .qwer {
      font-size: 2.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .qwer {
      font-size: 1.7rem;
    }
  }
  
  /* Global background override for this section */
  /* Change background to full black for the Home component */
  body.no-backgroundglb {
    background: #000 !important; /* Set background to solid black */
    background-color: #000 !important; /* Fallback for solid black */
  }
  
  /* Add these styles to your existing CSS */
  .main-textglb {
    font-size: 3.1rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
    opacity: 0; /* Start with invisible text */
    transform: translateY(-20px); /* Start slightly above */
    animation: fadeDown 1.5s ease-out forwards;
    color: transparent; /* Make text transparent for masking */
    background-image: url('../../../images/8197.jpg'); /* Replace with your desired image */
    background-size: 200%; /* Enlarged for smooth animation */
    background-position: 0 50%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: fadeDown 1.5s ease-out forwards, animate-background 5s infinite alternate linear;
  }
  
  @keyframes animate-background {
    0% {
      background-position: 0 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }

  /* Scroll Indicator Styles */
.scroll-indicator {
  position: absolute;
  bottom: 20px; /* Position at the bottom of the hero section */
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem; /* Size of the scroll indicator */
  color: white;
  opacity: 0.8; /* Slightly transparent for subtle effect */
  animation: bounce 1.5s infinite; /* Add bounce animation */
  cursor: pointer; /* Indicate that the indicator is clickable */
  z-index: 2; /* Ensure it's above the video */
}

.scroll-indicator:hover {
  opacity: 1; /* Fully visible on hover */
}

/* Bounce animation for scroll indicator */
@keyframes bounce {
  0%, 100% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, 10px);
  }
}