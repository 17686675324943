/* General Section Styles */
.reg-solutionglb {
    background-image: url('../../../images/regionalbg.jpg'); /* Adjust the path as needed */
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the image */
    display: flex; /* Flex layout for centering content */
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    padding: 60px; /* Add padding for spacing */
    min-height: 80vh; /* Full viewport height */
    color: white; /* White text color for readability */
    text-align: center; /* Center text alignment */
    margin: 0;
}

/* Animation for the main heading */
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Animation for the subheading */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.reg-main-heading {
    font-size: 3rem; /* Main heading font size */
    font-weight: bold; /* Bold font */
    margin-bottom: 20px; /* Spacing below heading */
    line-height: 1.2; /* Adjust line height for readability */
    animation: fadeInDown 1s ease-out forwards; /* Apply fadeInDown animation */
    opacity: 0; /* Start with opacity 0 for animation */
}

.reg-subheading {
    font-size: 1.25rem; /* Subheading font size */
    margin-bottom: 40px; /* Spacing below subheading */
    font-weight: 500; /* Semi-bold font for subheading */
    animation: fadeInUp 1s ease-out 0.5s forwards; /* Apply fadeInUp animation with delay */
    opacity: 0; /* Start with opacity 0 for animation */
}

.reg-scroll-indicator {
    margin-top: 30px;
    font-size: 2rem; /* Size of the scroll indicator */
    color: white;
    opacity: 0.8; /* Slightly transparent for subtle effect */
    animation: reg-bounce 1.5s infinite; /* Add bounce animation */
    cursor: pointer; /* Indicate that the indicator is clickable */
}

.reg-scroll-indicator:hover {
    opacity: 1; /* Fully visible on hover */
}

/* Bounce animation for scroll indicator */
@keyframes reg-bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
}

/* Next Section Styles */
.reg-next-section {
    background-color: #ffffff; /* Dark background color */
    color: #ffffff; /* White text color */
    padding: 60px 20px; /* Spacing around the section */
}

/* Two Columns Layout */
.reg-two-columns {
    display: flex;
    justify-content: space-between; /* Space between columns */
    align-items: center; /* Align content vertically */
    gap: 40px; /* Space between columns */
    max-width: 1200px; /* Limit the width of the section */
    margin: 0 auto; /* Center the section */
}

.reg-left-column {
    flex: 1; /* Take up available space */
    text-align: left; /* Align text to the left */
}

.reg-right-column {
    flex: 1; /* Take up available space */
    text-align: center; /* Center the image */
}

.reg-world-map {
    max-width: 100%; /* Ensure the image is responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Optional: Add rounded corners */
}

.reg-next-main-heading {
    font-size: 2.5rem; /* Large font size */
    font-weight: bold; /* Bold font */
    margin-bottom: 20px; /* Spacing below the heading */
    line-height: 1.2; /* Adjust line height */
    background: linear-gradient(90deg, #ff007f, #8f00ff); /* Gradient background */
    -webkit-background-clip: text; /* Clip the gradient to the text */
    -webkit-text-fill-color: transparent; /* Make the text transparent to show the gradient */
    display: inline-block; /* Ensure the gradient applies correctly */
}

.reg-next-subheading {
    font-size: 1.25rem; /* Subheading font size */
    margin-bottom: 20px; /* Spacing below subheading */
    color: #0000009e; /* Light gray for contrast */
    line-height: 1.6; /* Comfortable line height */
}

.reg-next-details {
    font-size: 1rem; /* Smaller font size for details */
    color: #000000; /* Softer gray color */
    margin-bottom: 40px; /* Spacing below details */
    line-height: 1.6; /* Comfortable line height */
}

/* Responsive Design */
@media (max-width: 768px) {
    .reg-two-columns {
        flex-direction: column; /* Stack columns vertically on smaller screens */
        gap: 20px; /* Reduce gap between columns */
    }

    .reg-left-column,
    .reg-right-column {
        text-align: center; /* Center-align content on smaller screens */
    }

    .reg-next-main-heading {
        font-size: 2rem; /* Reduce font size for smaller screens */
    }

    .reg-next-subheading {
        font-size: 1.1rem; /* Reduce font size for smaller screens */
    }

    .reg-next-details {
        font-size: 0.9rem; /* Reduce font size for smaller screens */
    }
}