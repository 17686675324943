.careers-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    padding: 50px 20px;
    min-height: 100vh;
  }
  
  .careers-content {
    background: rgba(255, 255, 255, 0.096); /* Semi-transparent background for readability */
    padding: 30px;
    border-radius: 10px;
    max-width: 900px;
    width: 100%;
  }
  
  .careers-content h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .careers-content p {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 20px;
  }
  
  .careers-section {
    margin-top: 30px;
    text-align: left;
  }
  
  .careers-section h2 {
    font-size: 2rem;
    margin-bottom: 15px;
  }
  
  .careers-section ul {
    list-style: disc;
    padding-left: 20px;
  }
  
  .careers-section ul li {
    font-size: 1rem;
    line-height: 1.8;
    margin-bottom: 10px;
  }
  
  .careers-links {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
  }
  
  .explore-button,
  .submit-button {
    background: #1e90ff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
