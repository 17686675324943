/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/

#FAQs {
    text-align: center!important;
    font-size: 40px!important;
    color: white!important;
    opacity: 0.9!important;
}

@media (max-width: 425px) {
    #FAQs {
        font-size: 15px !important;
    }
}

@media (max-width: 375px) {
    #FAQs {
        font-size: 15px !important;
    }
}

@media (max-width: 768px) {
    #FAQs {
        font-size: 21px !important;
    }
    
}

/* .FAQText {
    margin-bottom: 3% !important;
    margin-top: 6% !important;
} */

/* .Button{
    width: 160px;
} */


/* .card-body{
    width: 1000px;
}

.blogs {
    text-align: center;
    margin-bottom: 10px;
}


.blogTopic{
    width: 1000px !important;
} */

.card-header{
    background-color: rgb(212, 224, 216) !important;
}

.field{
    padding-top: 40px;
    padding-bottom: 140px;
}
