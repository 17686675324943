.spacer {
    width: 10px;
    /* Adjust this value as needed */
}

.body{
    background-image:none !important;
}


#floatingTextarea2 {
    height: 200px;
}

.btnContainer {
    text-align: center;
}

@media (max-width: 425px) {
    #Text {
        font-size: 15px;
    }
}

@media (max-width: 375px) {
    #Text {
        font-size: 15px;
    }
}

@media (max-width: 768px) {
    #Text {
        font-size: 21px;
    }

    .mapglb {
        /* margin-left: 30% !important; */
        margin-top: 50px;
    }

    .countryData {
        /* margin-left: 25% !important; */
        text-align: center;

    }

    .line {
        width: 350px;
    }

    .txtglb{
        text-align: left;
        margin-left: 20px;
    }
}

.raw {
    margin-top: 10px;
}

#Text {
    text-align: center;
    font-size: 40px;
    color: white;
    opacity: 0.9;
}

.frame {
    width: 100%;
    height: 100%;
    border-radius: 3%;
}

.inputField {
    /* margin-bottom: 10%; */
    margin-top: 10%;
}

.h1 {
    font-size: 60px !important;
    color: aqua !important;
}

.h2 {
    color: white !important;
    text-align: justify !important;
}

.tikBox {
    color: white;
}

.formcheck {
    margin-top: 20px;
}

.countryData {
    margin-top: 4% !important;
    color: white;
}

.countryName {
    font-size: 50px;
}

.callIcon3 {
    margin-top: 3%;
    margin-right: 23px;
    color: white;
}

.img3 {
    margin-right: 28px;
    color: white;
}

.address3 {
    /* margin-left: 1%; */
    padding-top: 2px;
}

.address {
    margin-left: 40px;
}

.txtglb {
    margin-top: 30px;
    font-style: oblique;
    font: bold
}

.txtarea {
    height: 70px !important;
}

.line {
    width: 50%;
}

.row2 {
    background-color: rgba(66, 66, 66, 0.41);
    padding-left: 4%;
    border-radius: 40px;
    margin-bottom: 4%;
    margin-top: 4% !important;
}


/* GetDirectionsButton.css */

.neon-button {
    background-color: #000;
    color: #00ffff;
    /* Neon blue color */
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.8);
    /* Neon glow effect */
    width: 200px;
    margin-left: 3px;
    margin-top: 13px !important;
}

.neon-button:hover {
    box-shadow: 0 0 20px rgba(0, 255, 255, 1);
    /* Increased glow on hover */
}

.is-invalid {
    border: 2px solid red;
}

.text-danger {
    color: red;
    font-size: 0.875em;
    margin-top: 0.25em;
}

.mapglb{
    width: 110%;
    margin-left: -50px;
}
.line{
    display: none;
}
/* Feedback Button Styling */
.feedback-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    color: #000000; /* Red color for the text */
    border: 2px solid #00ffff; /* Red border */
    border-radius: 50px; /* Rounded edges */
    padding: 1px 10px; /* Padding for the button */
    font-size: 16px; /* Font size for the text */
    font-weight: bold; /* Bold text */
    text-transform: uppercase; /* Uppercase text */
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    outline: none; /* Remove outline */
    margin-top: 15px;
    height: 35px !important;
}

/* Red circle with the arrow */
.feedback-button::after {
    content: '➔'; /* Right arrow symbol */
    background-color: #76b9fd; /* Red background */
    color: white; /* White arrow */
    border-radius: 50%; /* Make the circle */
    padding: 10px;
    margin-left: 15px; /* Space between the text and circle */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: #ff3b30 !important;
    height: 35px !important;
}

/* Button hover effect */
.feedback-button:hover {
    background-color: #ff3b30; /* Change background to red */
    color: white; /* White text */
    border: #ff3b30 !important;
}

.feedback-button:hover::after {
    background-color: white; /* Inverse background for the arrow */
    color: #ff3b30; /* Red arrow */
    border: #ff3b30 !important;
}

.blue-text {
    background: linear-gradient(90deg, #00eaff, #e8eeff); /* Gradient from light blue to dark blue */
    -webkit-background-clip: text; /* Clip the gradient to the text */
    -webkit-text-fill-color: transparent; /* Make the text transparent to show the gradient */
    font-weight: bold; /* Optional: Make the text bold */
  }
  

/* Add this to your Contact.css file */

.containerglobal {
    background-image: url('../../images/gradient-blue-abstract-background-smooth-dark-blue-with-black-vignette-studio\ \(1\).jpg'); /* Adjust the path as needed */
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the image */
    padding: 60px; /* Add some padding to ensure content is not too close to the edges */
    min-height: 100vh; /* Ensure the container covers the full viewport height */
    color: white; /* Ensure text is readable on the background */
    margin-top: 10px;
}

