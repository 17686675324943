.hero1 {
  width: 100%;
  min-height: 70vh;
  position: relative;
  padding-top: 35px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid rgba(var(--accent-color-rgb), 0.25);
}


.rowmargin {
  height: 85%;
  padding-bottom: 5%;
  padding-top: 3%;
}

.cardSize {
  height: 70px;
  padding: 0% !important;
}

.cardSize1{
  height: 60px;
}

.crd {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  opacity:calc(0.9);
  
  
}

.crd:hover {
  transform: translateY(-7px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  opacity:calc(1);
}



#topic {
  text-align: center;
  font-size: 40px;
  color: white;
  opacity: 0.9;
}

.card-body-brunai{
  height: 80px;
}


/* Styles for devices with a max-width of 425px */
@media (max-width: 425px) {
  #topic {
    font-size: 15px;
  }

}

/* Styles for devices with a max-width of 375px */
@media (max-width: 375px) {
  #topic {
    font-size: 15px;
  }
 
}

/* Styles for devices with a max-width of 650px */
@media (max-width: 650px) {

  .crd {
    padding-bottom: 5%;
  }
}


/* Styles for devices with a max-width of 1024px */
@media (max-width: 1024px) {
  .crd {
    padding-bottom: 6%;
  }

  .datacenter {
    height: 147px;
  }
}

/* Styles for devices with a min-width of 1440px */
@media (min-width: 1440px) {
  .datacenter {
    height: 206px;
  }
}


/* Styles for devices with a width between 1024px and 1440px */
@media (min-width: 1024px) and (max-width: 1439px) {
  .crd {
    padding-bottom: 6%;
  }

  .datacenter {
    height: 147px;
  }
}


.txts{
  font-size: 20px;
  font: bold;
  font-weight: 600;
  padding: 0 !important;

}

.txts1{
  font-size: 20px;
  font: bold;
  font-weight: 600;
  padding: 0 !important;
}


@media (max-width: 767px) {
  #topic {
    font-size: 21px;
  }

  .datacenter {
    height: 230px !important  ;
  }

}

@media (max-width: 767px) {
  #topic {
    font-size: 21px;
  }
  
  .crd {
    padding-bottom: 5%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #topic {
    font-size: 21px;
  }
  .crd {
    padding-bottom: 5%;
  }
  .datacenter {
    height: 230px;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  
  .datacenter {
    height: 130px !important;
  }
}


@media  (min-width: 700px) {
  
  .rowmargin {
    margin-bottom: -110px;
  }
}

.heroSolution{
  margin-bottom: 150px;
}


.solImageHight{
  height: 265px !important;
}



/* Styles for devices with a max-width of 1024px */
@media (max-width: 1024px) {
  .crd {
    padding-bottom: 6%;
  }

  .datacenter {
    height: 147px;
  }

  .card-img-top.endpoint {
    height: 265px; /* Adjust this height as needed */
  }
}
