@media (min-width: 600px) {
    .center {
      margin-left: 10%;
    }
  
    .container {
      width: 50%;
    }
  
    #t1 {
      background-color: #10055b25;
      padding: 2%;
      /* border-bottom: 1px solid green; */
      margin-left: -7%;
    } 
  
    #t2 {
      background-color: #21025a54;
      padding: 2%;
      /* border-bottom: 1px solid green; */
    }
  
    .headimg {
      width: 70%;
      margin-top:  5%;
      border-radius: 50px;
      align-items: center;
      border: 3px solid #4EC3F5;
    }
  
    .story {
      width: 70%;
      color: white;
      margin-top: 15%;
      text-align: justify;
      padding: 4%;
    }
  
    .bdround {
      border-left: 2px solid #4EC3F5;
      /* Set left border */
      border-right: 2px solid #4EC3F5;
      border-radius: 50px;
    }
  
    .spc {
      height: 50px;
    }
  
    .slider {
      margin: auto;
      width: 60%;
      height: 100%;
      background-color: #f9f9f921;
      align-items: center;
      padding: 1%;
      border-radius: 50px;
      margin-top: 2%;
    }
  
    .slider .sl img {
      width: 60%;
      margin-left: 20%;
      border-radius: 40px;
      border: 3px solid #4EC3F5;
  
    }
  
    .slider .sl h3 {
      margin-top: 10%;
      margin-left: -15%;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
  
    .slider .sl h4 {
      margin-top: 5%;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      font-style: italic;
      color: rgb(4, 137, 8);
    }
  
    .slider .sl p {
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      font-style: italic;
      color: rgb(4, 137, 8);
    }
  
    .sld {
      width: 80%;
      background-color: rgba(109, 109, 131, 0.652);
      margin: 2%;
      padding: 2%;
      border-radius: 50px;
      float: left;
    }
  
    .sld .im {
      width: 50%;
      margin: 2%;
      float: left;
    }
  
    .sld .im img {
      width: 100%;
      border-radius: 40px;
      border: 3px solid #4EC3F5;
  
  
    }
  
    .sld .qut {
      width: 130%;
      margin-left: -40%;
      float: left;
  
    }
  
    .sld .qut .qt {
      color: #ffffff;
      font-size: 175%;
      font-weight: bold;
      background-color: #01476F;
      border-radius: 0px 50px 50px 50px;
      padding: 5%;
      width: 100%;
    }
  
    .nmt {
      margin-top: 7%;
      padding-left: 6%;
      padding-right: 5%;
      padding-top: 0.5%;
      padding-bottom: 0.5%;
      width: 100%;
      font-style: italic;
  
  
    }
  
    .nmt h4 {
      color: rgb(248, 248, 248);
      margin: 2%;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    }
  
    .nmt p {
      color: #FFAA05;
      margin: 2%;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      font-size: 20px;
    }
  
    .sldr {
      padding-left: 0%;
      margin-left: 0%;
      margin-top: 10%;
      width: 85%;
    }
  
    .arrpre {
      width: 10px;
      background-color: #FFAA05;
    }
  
    button {
      width: 100px;
    }
  
    .map {
      width: 100%;
      height: auto;
  
    }
  
    .imageMap {
      margin-bottom: 40px;
      margin-left: 10%;
      
    }
    
    .text{
      margin-left: -4%;
    }
    
    
  
  }
  
  @media (max-width: 599px) {
  
    .headimg {
      width: 80%;
      margin-top: 5%;
      border-radius: 50px;
      align-items: center;
      border: 3px solid #4EC3F5;
      /* margin-left: 10%; */
    }
  
    .bdround {
      border-left: 2px solid #4EC3F5;
      /* Set left border */
      border-right: 2px solid #4EC3F5;
      border-radius: 50px;
         
    }
  
    .bdround2{
      margin-bottom: 10% !important;
    }
  
    .story {
      width: 90%;
      color: white;
      margin-top: 15%;
      text-align: justify;
      /* padding: 4%; */
      text-align: center;
      margin-left: 15%;
    }
  
    .slider {
      margin: auto;
      width: 80%;
      height: 100%;
      background-color: #f9f9f921;
      align-items: center;
      padding: 2%;
      border-radius: 50px;
      margin-top: 8% !important;
      margin-bottom: 10% !important;
  
  
    }
  
    .slider .sl img {
      width: 80%;
      margin-left: 10%;
      margin-top: 10%;
      border-radius: 40px;
      border: 3px solid #4EC3F5;
  
    }
  
    .slider .sl h3 {
      text-align: center;
      margin-top: 10%;
      font-size: 20px;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
     padding-left: 13%;
    }
  
    .slider .sl h4 {
      text-align: center;
      margin-top: 5%;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      font-style: italic;
      color: rgb(4, 137, 8);
      font-size: 18px !important;
     
    }
  
    .slider .sl p {
      text-align: center;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      font-style: italic;
      color: rgb(4, 137, 8);
      font-size: 13px !important;
    }
  
    .map {
      width: 365px;
      margin-left: -90px;
      margin-bottom: 50PX;
    }
  
    .map{
      width: 370px !important;
      margin-left: -75px;
    }
  }
  
  /* ////////////////// */
  
  #t1 {
    background-color: #10055b25;
    padding: 2%;
    /* border-bottom: 1px solid green; */
  
  }
  
  #t2 {
    background-color: #21025a54;
    padding: 2%;
    /* border-bottom: 1px solid green; */
  }
  
  .headimg {
    width: 80%;
    margin-top: 20%;
    border-radius: 50px;
    align-items: center;
    border: 3px solid #4EC3F5;
  }
  
  .story {
    /* increasing the with of vision mission */
    width: 90%;  
    color: white;
    margin-top: 15%;
    text-align: justify;
    padding: 4%;
    margin-left: 5%;
    
  }
  
  .bdround {
    border-left: 2px solid #4EC3F5;
    /* Set left border */
    border-right: 2px solid #4EC3F5;
    border-radius: 50px;
  }
  
  .bdround1{
   padding-bottom: 44px;
  }
  
  .spc {
    height: 50px;
  }
  
  .slider {
    margin: auto;
    width: 70%;
    height: 100%;
    background-color: #f9f9f921;
    align-items: center;
    padding: 1%;
    border-radius: 50px;
    margin-top: 2%;
  }
  
  .slider .sl img {
    width: 60%;
    margin-left: 20%;
    border-radius: 40px;
    border: 3px solid #4EC3F5;
  
  }
  
  .slider .sl h3 {
    margin-top: 10%;
    margin-left: -10%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
  }
  
  .slider .sl h4 {
    margin-top: 5%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: italic;
    color: rgb(4, 137, 8);
    font-size: 35px;
    
  }
  
  .slider .sl p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-style: italic;
    color: rgb(4, 137, 8);
    font-size: 20px;
  }
  
  .sld {
    width: 80%;
    background-color: rgba(109, 109, 131, 0.652);
    margin: 2%;
    padding: 2%;
    border-radius: 50px;
    float: left;
  }
  
  .sld .im {
    width: 50%;
    margin: 2%;
    float: left;
  }
  
  .sld .im img {
    width: 100%;
    border-radius: 40px;
    border: 3px solid #4EC3F5;
  
  
  }
  
  .sld .qut {
    width: 130%;
    margin-left: -40%;
    float: left;
  
  }
  
  .sld .qut .qt {
    color: #ffffff;
    font-size: 175%;
    font-weight: bold;
    background-color: #01476F;
    border-radius: 0px 50px 50px 50px;
    padding: 5%;
    width: 100%;
  }
  
  .nmt {
    margin-top: 7%;
    padding-left: 6%;
    padding-right: 5%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    width: 100%;
    font-style: italic;
  
  
  }
  
  .nmt h4 {
    color: rgb(248, 248, 248);
    margin: 2%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  
  .nmt p {
    color: #FFAA05;
    margin: 2%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 20px;
  }
  
  .sldr {
    padding-left: 0%;
    margin-left: 0%;
    margin-top: 10%;
    width: 85%;
  }
  
  .arrpre {
    width: 10px;
    background-color: #FFAA05;
  }
  
  button {
    width: 100px;
  }
  
  .map {
    width: 100%;
    height: auto;
  
  }
  
  .imageMapglb {
    margin-bottom: 2px;
   
    /* padding: 20px; */
  }
  
  .classss{
    text-align: center;
  }
  
  .topic{
    text-align: center;
  }
  
  .storyCNX{
    color: #72d551;
  }

  .section-with-bg {
    background-image: url('../../../images/abtbg2\ \(2\).jpg'); /* Replace with your image path */
    background-size: cover; /* Cover the entire section */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent image repetition */
    padding: 60px 20px; /* Add padding for spacing */
    color: #ffffff; /* White text for better contrast */
}
.section2-with-bg {
    background-image: url('../../../images/gradient-blue-abstract-background-smooth-dark-blue-with-black-vignette-studio\ \(1\).jpg'); /* Replace with your image path */
    background-size: cover; /* Cover the entire section */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent image repetition */
    padding: 60px 20px; /* Add padding for spacing */
    color: #ffffff; /* White text for better contrast */
}

