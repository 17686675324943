/* Portal.css */

/* Container styling */
.Portalcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
    margin-top: 20px !important;
}

.Portalcontainer2 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
    margin-top: 20px !important;
    padding: 20px;
    color: white;
    /* background-color: rgba(255, 255, 255, 0.173); */
}

/* Content box styling with gradient and background image */
.portal-content-box {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 1),  rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), 
                      url('../../images/Portal/IMAGE1.png'); /* Gradient + Image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    text-align: left;
    max-width: 600px;
    width: 100%;
    color: white;
    padding-left: 50px !important;
}

/* Right image styling for the second section */
.right-image {
    width: 100%;
    height: 65% !important;
    border-radius: 15px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

/* Button row styling for second section */
.Portal-button-row {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    text-align: left;
}

/* Become a partner button styling */
.join-button, .explore-button, .learn-more-button {
    background-color: white;
    color: #0078d4;
    padding: 10px 25px;
    border: 2px solid white;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    text-transform: none;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.join-button:hover, .explore-button:hover, .learn-more-button:hover {
    background-color: transparent;
    color: white;
    border: 2px solid white;
}

.join-button::after, .explore-button::after, .learn-more-button::after {
    content: ' ➔';
    margin-left: 8px;
}

/* Hover effect for buttons */
.cta-button:hover {
    box-shadow: 0 0 3px rgb(255, 255, 255), 0 0 3px rgb(255, 255, 255);
    transform: scale(1.05);
}

/* Sign in button styling */
.sign-in-button {
    background-color: transparent;
    color: white;
    padding: 10px 25px;
    border: 2px solid white;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    text-transform: none;
    transition: all 0.3s ease-in-out;
}

.sign-in-button:hover {
    background-color: rgb(0, 0, 0);
}

.sign-in-button::after {
    content: ' ➔';
    margin-left: 8px;
}

.explore-button{
    background-color: transparent;
    color: white;
    padding: 10px 25px;
    border: 2px solid white;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    text-transform: none;
    transition: all 0.3s ease-in-out;   
    width: 175px !important;
}

.contentTwo{
    padding: 15px !important;
}

/* Mobile Devices (max-width: 600px) */
@media (max-width: 600px) {
    .container {
        padding: 10px;
    }

    .portal-content-box {
        padding: 20px;
        max-width: 100%;
        padding-left: 20px !important;
        padding-right: 20px;
    }

    .portal-content-box h2 {
        font-size: 1.5rem; /* Smaller heading for mobile */
    }

    .portal-content-box p {
        font-size: 1rem; /* Smaller paragraph text for mobile */
        padding-right: 20px;
    }

    .Portal-button-row {
        flex-direction: column;
        align-items: stretch;
        gap: 10px; /* Stack buttons vertically */
    }

    .cta-button {
        width: 100%; /* Make buttons full width */
    }

    .right-image {
        width: 100%;
        height: auto;
        margin-top: 20px;
    }

    
    
}

@media (min-width: 370px) and (max-width: 751px) {
    .Portalcontainer2{
        margin-bottom:150px !important;
    }

    .contentTwo{
        margin-top: -90px !important;
    }

    
}


/* Tablet Devices (min-width: 601px and max-width: 900px) */
@media (min-width: 601px) and (max-width: 900px) {
    .container {
        padding: 20px;
    }

    .portal-content-box {
        padding: 30px;
        max-width: 100%;
        padding-left: 30px !important;
        padding-right: 30px;
    }

    .portal-content-box h2 {
        font-size: 1.6rem; /* Adjust heading for tablet */
    }

    .portal-content-box p {
        font-size: 1.1rem; /* Adjust paragraph text for tablet */
        padding-right: 30px;
    }

    .Portal-button-row {
        gap: 15px;
    }

    .cta-button {
        width: auto; /* Allow buttons to scale naturally */
    }

    .right-image {
        width: 100%;
        height: auto;
    }

     .Portalcontainer2{
        margin-bottom:150px !important;
    }

    .contentTwo{
        margin-top: -200px !important;
    }

    .cardFour{
        margin-bottom: 5px;
    }
}

/* Large Desktop (min-width: 901px) */
@media (min-width: 901px) {
    .container {
        padding: 40px;
    }

    .portal-content-box {
        padding: 40px;
        max-width: 600px;
        padding-left: 50px !important;
        padding-right: 50px;
    }

    .portal-content-box h2 {
        font-size: 2rem; /* Slightly larger heading for large screens */
    }

    .portal-content-box p {
        font-size: 1.2rem; /* Slightly larger paragraph text for large screens */
        padding-right: 50px;
    }

    .Portal-button-row {
        gap: 20px;
    }

    .cta-button {
        width: 150px;
    }

    .right-image {
        width: 100%;
        height: auto;
    }

    
}

@media (min-width: 1022px) and (max-width: 1435px){
    .Portalcontainer2{
        margin-bottom:100px !important;
    }
}

/* 3rd Section - Get Started */
.Portalcontainer3 {
    background-color: #f9f9f911;
    padding: 40px 20px;
    border-radius: 10px;
    text-align: center;
    margin-top: -140px !important;
    color: #ffffff;
}



.get-started-portal h2 {
    font-size: 2.5rem;
    margin-bottom: 20px; 
    color: #0078d4;
    
}

.get-started-portal p {
    font-size: 1.2rem;
    margin-bottom: 40px;

}

/* Step Box Styling */
.step-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

.step-box.cardFour {
    background-color:rgba(255, 255, 255, 0.093);
    color: #0078d4;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    min-height: 200px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
}

.step-box.cardFour:hover {

    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
}

.step-box h3 {
    font-size: 1.4rem;
    margin-bottom: 15px;
    color: #ffffffcb !important;
}

.step-box p {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #ffffffd3;
    flex-grow: 1;
}

.step-box a {
    font-weight: bold;
    color: #0078d4;
    text-decoration: none;
}

.step-icon {
    background-color: #0078d4;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 0 auto 20px auto;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
}

/* Responsive Design */
@media (max-width: 600px) {
    .step-row {
        flex-direction: column;
        align-items: stretch;
    }

    .step-box {
        margin-bottom: 20px;
    }
}
/* Get Started Button */
.get-started-button {
    background-color: transparent;
    color: #ffffff;
    padding: 10px 25px;
    border: 2px solid #ffffff; /* White border */
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    text-transform: none;
    box-shadow: none;
    width: 170px;
}

.get-started-button::after {
 
    margin-left: 8px;
    transition: margin-left 0.3s ease-in-out; /* Smooth arrow slide */
}

/* Hover Effect */
.get-started-button:hover {
    background-color: #ffffff; /* White background on hover */
    color: #0078d4; /* Blue text on hover */
    border: 2px solid #ffffff; /* Keep the white border */
    transform: scale(1.05);  /* Slight scaling effect on hover */
}

.get-started-button:hover::after {
    margin-left: 12px; /* Move arrow on hover */
}

.get-started-button:focus {
    outline: none;  /* Remove default button outline on focus */
}

/* Button container for positioning */
.getStarted-button-row {
    margin-top: 15px !important;
    text-align: center; /* Center-align the button */
}

/* section 4 */

.sectionFour {
    align-items: center;
    margin-top: 50px;
    background-color: #f9f9f925;
    min-height: 50vh;
    border-radius: 10px;
    padding: 20px;
}

.sectionFour h2 {
    color: white;
    text-align: center !important;
    padding: 20px;
}

/* Card Styling */
.cardFour {
    background-color: #ffffff58;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
    text-align: center;
    transition: all 0.3s ease-in-out;
   
}

.cardFour:hover {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
}

/* Scrollable Card */
.scrollable-cardFour {
    max-height: 210px; /* Limit the height of the cardFour */
    overflow-y: scroll; /* Enable vertical scrolling */
    background-color: #121c438d !important;
 
}

.content-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.content-list li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-size: 1rem;
    color: #ffffff;
}

.content-list li:last-child {
    border-bottom: none;
}

/* Custom scrollbar styling */
.scrollable-cardFour::-webkit-scrollbar {
    width: 5px;
}

.scrollable-cardFour::-webkit-scrollbar-thumb {
    background-color: #0078d4;
    border-radius: 10px;
}

.scrollable-cardFour::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

/* Non-scrollable cardFour styling */
.non-scrollable-cardFour {
    height: 210px !important; /* Fixed height for the cardFour */
    background-color: #121c438d !important;
    display: flex; /* Use flexbox to align content */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    padding: 20px; /* Add some padding for inner content */
    text-align: left; /* Align text to left for better readability */
    border-radius: 10px; /* Rounded corners for consistency */
    overflow: hidden; /* Prevent overflow of content */
}

/* List styling */
.icon-list {
    list-style-type: none; /* Remove default bullets */
    padding: 0;
    margin: 0;
}

.icon-list li {
    display: flex;
    align-items: center;
    font-size: 1.1rem; /* Font size for the list items */
    padding: 1px 0;
    color: white; /* White text for list items */
}

.icon-list li .icon {
    margin-right: 1px; /* Space between the icon and the text */
    font-size: 0.8rem; /* Adjust icon size */
    color: #ffffff; /* Icon color */
}

/* Ensure both cardFours look consistent */
.scrollable-cardFour, .non-scrollable-cardFour {
    background-color: #121c438d !important;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
}

.scrollable-cardFour ul, .non-scrollable-cardFour ul {
    margin: 0;
    padding-left: 0; /* Remove left padding */
}

.scrollable-cardFour ul li, .non-scrollable-cardFour ul li {
    margin-bottom: 10px;
    list-style: none;
}

/* Text inside non-scrollable cardFour */
.non-scrollable-cardFour p {
    color: white !important;
    font-size: 1.2rem;
    padding: 10px 20px; /* Add some padding for inner content */
}

/* Large font size for "Hello Connex" */
.large-font11 {
    font-size: 2.9rem !important; /* Increase font size for "Hello Connex" */
    text-align: center; /* Center the text */
    color: #ffffff; /* Optional: white color */
}



