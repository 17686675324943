.blogimg {
    padding: 4px;
    border-radius: 20px;
    width: 300px;
}

.cards2 {
    background-color: rgba(201, 209, 216, 0.384) !important;
    color: white !important;
}


.modal {
    padding-top: 100px;
}

@media(max-width: 675px) {
    .cards {
        margin: 10px;
        /* Add margin to cards for better spacing */
    }
}

#UpcomingNewsText {
    text-align: center;
    font-size: 40px;
    color: white;
    opacity: 0.9;
}

@media (max-width: 425px) {
    #UpcomingNewsText {
        font-size: 15px !important;
    }
}

@media (max-width: 375px) {
    #UpcomingNewsText {
        font-size: 15px !important;
    }
}

@media (max-width: 768px) {
    #UpcomingNewsText {
        font-size: 21px !important;
    }
}


.read-more {
    position: absolute;
    bottom: 10px;
    right: 20px;
    width: 140px !important;
    border: 120px;
}

.headingrow {
    padding-bottom: 14px;
}

.buttnRow {
    /* margin-top: 70px !important; */
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    text-align: justify;
}

.upcomingDis {
    padding: 50px;
    font-size: larger;
}

.form-group {
    margin-top: 10px;
}

.upheading {
    text-align: center;
    padding-top: 10px;
}
.locationSelect{
    margin-top: 10px !important;
}

.microsoftcard{
    margin-top: 14px !important;
}

.mcroimg{
    width: 400px !important;
}

/* Custom styles for the Copilot modal */

.copilot-modal .modal-header {
    border-bottom: none;
    padding-bottom: 0;
}

.copilot-modal .modal-body {
    background: linear-gradient(135deg, #f0e4f7 0%, #c8e4fb 100%); /* Updated gradient */
    padding: 0;
}

.copilot-modal .modal-footer {
    border-top: none;
    padding-top: 0;
}

.copilot-modal .btn-primary {
    background-color: #0078d4; /* Microsoft's primary blue color */
    border-color: #0078d4;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Button shadow */
}

.copilot-modal .modal-title {
    font-weight: bold;
    color: #333;
}
