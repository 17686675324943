/* General Section Styles */
.solutionglb {
    background-image: url('../../../images/freepik__adjust__50868.png'); /* Adjust the path as needed */
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the image */
    display: flex; /* Flex layout for centering content */
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    padding: 60px; /* Add padding for spacing */
    min-height: 80vh; /* Full viewport height */
    color: white; /* White text color for readability */
    text-align: center; /* Center text alignment */
    margin: 0;
}

/* Animation for the main heading */
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Animation for the subheading */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.main-heading {
    font-size: 3rem; /* Main heading font size */
    font-weight: bold; /* Bold font */
    margin-bottom: 20px; /* Spacing below heading */
    line-height: 1.2; /* Adjust line height for readability */
    animation: fadeInDown 1s ease-out forwards; /* Apply fadeInDown animation */
    opacity: 0; /* Start with opacity 0 for animation */
}

.subheading {
    font-size: 1.25rem; /* Subheading font size */
    margin-bottom: 40px; /* Spacing below subheading */
    font-weight: 500; /* Semi-bold font for subheading */
    animation: fadeInUp 1s ease-out 0.5s forwards; /* Apply fadeInUp animation with delay */
    opacity: 0; /* Start with opacity 0 for animation */
}

.scroll-indicator {
    margin-top: 30px;
    font-size: 2rem; /* Size of the scroll indicator */
    color: white;
    opacity: 0.8; /* Slightly transparent for subtle effect */
    animation: bounce 1.5s infinite; /* Add bounce animation */
    cursor: pointer; /* Indicate that the indicator is clickable */
}

.scroll-indicator:hover {
    opacity: 1; /* Fully visible on hover */
}

/* Bounce animation for scroll indicator */
@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
}

/* Next Section Styles */
.next-section {
    background-color: #0f0e23; /* Dark background color */
    color: #ffffff; /* White text color */
    text-align: center; /* Center text alignment */
    padding: 60px 20px; /* Spacing around the section */
}

.next-main-heading {
    font-size: 3rem; /* Large font size */
    font-weight: bold; /* Bold font */
    background: linear-gradient(90deg, #ff007f, #8f00ff); /* Gradient text effect */
    -webkit-background-clip: text; /* Clip gradient to text */
    -webkit-text-fill-color: transparent; /* Transparent fill for gradient text */
    margin-bottom: 20px; /* Spacing below the heading */
    line-height: 1.2; /* Adjust line height */
}

/* Three Columns Layout */
.three-columns {
    display: flex;
    justify-content: center; /* Center the columns */
    align-items: center; /* Align content vertically */
    gap: 20px; /* Space between columns */
    margin-top: 40px; /* Add spacing above the columns */
}

.column {
    flex: 1; /* Equal width for all columns */
}

.middle-column {
    flex: 2; /* Make the middle column wider */
    text-align: center; /* Center-align the content in the middle column */
}

.next-subheading {
    font-size: 1.4rem; /* Subheading font size */
    font-weight: bold; /* Bold font for emphasis */
    margin-bottom: 20px; /* Spacing below the subheading */
    color: #e0e0e0; /* Light gray for contrast */
    line-height: 1.6; /* Comfortable line height */
}

.next-details {
    font-size: 1rem; /* Smaller font size for detailed text */
    color: #b0b0b0; /* Softer gray color */
    margin-bottom: 40px; /* Spacing below details */
    line-height: 1.6; /* Comfortable line height for reading */
}

/* Buttons (if needed for further sections) */
.buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center buttons */
    gap: 20px; /* Space between buttons */
    margin-top: 20px;
}

.button {
    background: transparent;
    color: #ff007f; /* Button text color */
    border: 2px solid #ff007f; /* Border matching the text color */
    padding: 12px 20px; /* Padding inside the button */
    border-radius: 5px; /* Rounded corners */
    font-size: 1rem; /* Button text size */
    font-weight: bold; /* Bold font for emphasis */
    text-transform: uppercase; /* Uppercase text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.button:hover {
    background: #ff007f; /* Fill background on hover */
    color: #ffffff; /* Change text color on hover */
}