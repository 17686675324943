/* Nav Menu Colors */
:root {
  --nav-color: #ffffff;
  --nav-hover-color: #00ff59;
  --nav-dropdown-color: #ffffff;
  --nav-dropdown-hover-color: #00b7cf;
  --nav-dropdown-background-color: #ffffff60;
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

.rndr {
  border-radius: 20px;
}

a {
  color: var(--accent-color);
  text-decoration: none !important;
  transition: 0.3s;
}

a:hover {
  /* color: rgba(var(--accent-color-rgb), 0.75); */
  text-decoration: none;
}

/*--------------------------------------------------------------
# Global headerglb
--------------------------------------------------------------*/
.headerglb {
  color: var(--default-color);
  background-color: rgba(0, 0, 0, 0.67);
  padding: 15px 0;
  transition: all 0.5s;
  z-index: 997;
  box-shadow: 0px 0 15px rgba(var(--default-color-rgb), 0.1);
  height: 65px;
  font-weight: bolder;
}

.headerglb .logo {
  line-height: 1;
}

.headerglb .logo img {
  max-height: 36px;
  margin-right: 8px;
}

.headerglb .logo h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 400;
  letter-spacing: 2px;
  color: var(--heading-color);
}

.headerglb .btn-getstarted,
.headerglb .btn-getstarted:focus {
  color: var(--contrast-color);
  background: #16a800;
  font-size: 14px;
  padding: 8px 25px;
  margin: 0 0 0 30px;
  border-radius: 50px;
  transition: 0.3s;
}

.headerglb .btn-getstarted:hover,
.headerglb .btn-getstarted:focus:hover {
  color: var(--contrast-color);
  background: #0004ff;
}

@media (max-width: 1200px) {
  .headerglb .logo {
    order: 1;
  }

  .headerglb .btn-getstarted {
    order: 2;
    margin: 0 15px 0 0;
    padding: 6px 15px;
  }

  .headerglb .navmenu {
    order: 3;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Navmenu - Desktop */
@media (min-width: 1200px) {
  .navmenu {
    padding: 0;
  }

  .navmenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navmenu li {
    position: relative;
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-color);
    padding: 18px 15px;
    font-size: 16px;
    font-family: var(--nav-font);
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    transition: 0.3s;
  }

  .navmenu li:last-child a {
    padding-right: 0;
  }

  .navmenu li:hover > a,
  .navmenu .active,
  .navmenu .active:focus {
    color: #00ff59;
    transform: scale(1.1);
  }

  .navmenu .li:active {
    transform: scale(0.95);
  }

  .navmenu .dropdown ul {
    margin: 0;
    padding: 10px 0;
    background: var(--nav-dropdown-background-color);
    display: block;
    position: absolute;
    visibility: hidden;
    left: 14px;
    top: 130%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
    box-shadow: 0px 0px 30px rgba(var(--default-color-rgb), 0.15);
  }

  .navmenu .dropdown ul li {
    min-width: 200px;
  }

  .navmenu .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--nav-dropdown-color);
  }

  .navmenu .dropdown ul a i {
    font-size: 12px;
  }

  .navmenu .dropdown ul a:hover,
  .navmenu .dropdown ul .active:hover,
  .navmenu .dropdown ul li:hover > a {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navmenu .dropdown .dropdown ul {
    top: 0;
    left: -90%;
    visibility: hidden;
  }

  .navmenu .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: -100%;
    visibility: visible;
  }
}

/* Navmenu - Mobile */
@media (max-width: 1199px) {
  .mobile-nav-toggle {
    color: var(--nav-color);
    font-size: 28px;
    line-height: 0;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .countries {
    margin-left: 5%;
    padding-top: 10px;
  }

  .navmenu {
    padding: 0;
    z-index: 9997;
  }

  .navmenu ul {
    display: none;
    position: absolute;
    inset: 60px 20px 20px 20px;
    padding: 10px 0;
    margin: 0;
    border-radius: 6px;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid rgba(var(--default-color-rgb), 0.1);
    box-shadow: 0px 0px 30px rgba(var(--default-color-rgb), 0.1);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  }

  .navmenu a,
  .navmenu a:focus {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: var(--nav-font);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: rgba(var(--accent-color-rgb), 0.1);
  }

  .navmenu a i:hover,
  .navmenu a:focus i:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }

  .navmenu a:hover,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .active i,
  .navmenu .active:focus i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .navmenu .dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    transition: all 0.5s ease-in-out;
  }

  .navmenu .dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .dropdown > .dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    font-size: 32px;
    top: 15px;
    right: 15px;
    margin-right: 0;
    z-index: 9999;
  }

  .mobile-nav-active .navmenu {
    position: fixed;
    overflow: hidden;
    inset: 0;
    background: rgba(33, 37, 41, 0.8);
    transition: 0.3s;
  }

  .mobile-nav-active .navmenu > ul {
    display: block;
  }
}

.no-bullets {
  list-style-type: none;
}

.arrow1 {
  margin-right: 1px !important;
}

/* Custom scrollbar styling for .scrollable-menu */
.scrollable-menu {
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #000b25 rgba(0, 0, 0, 0.1); /* For Firefox */
}

/* Custom scrollbar for WebKit browsers (Chrome, Safari, Edge) */
.scrollable-menu::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.scrollable-menu::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Track color */
  border-radius: 4px; /* Rounded corners for the track */
}

.scrollable-menu::-webkit-scrollbar-thumb {
  background: #0026ff; /* Thumb color */
  border-radius: 4px; /* Rounded corners for the thumb */
}

.scrollable-menu::-webkit-scrollbar-thumb:hover {
  background: #00b7cf; /* Thumb color on hover */
}

.flag {
  width: 20%;
}

.flagNavbar {
  margin-left: 1px;
}

.arrow1 {
  margin-right: -20px;
}

.containerglb {
  display: block;
  padding: 10px;
  background-color: #ffffff1a; /* Background color for visibility */
  border: 1px solid #ddd; /* Optional border for clarity */
  width: 800px; /* Dropdown width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for better design */
  border-radius: 5px; /* Optional: Rounded corners */
  position: absolute; /* Position the dropdown */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* Adjust it to start from the middle */
  z-index: 99; /* Ensure it's above other elements */
  top: 100%; /* Position it below the navlink */
}

.scrollable-menu1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px; /* Padding within the dropdown */
}

.dropdown-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.dropdown-row li {
  list-style: none;
  margin: 0;
}

.dropdown-row a {
  text-decoration: none;
  color: inherit;
  padding: 5px 10px;
  white-space: nowrap;
}

.dropdown-row a:hover {
  border-radius: 4px;
}

.dropdown-heading {
  padding: 10px 20px;
  background-color: #f9f9f9; /* Light background color for the heading */
  border-bottom: 1px solid #ddd; /* Divider between the heading and links */
}

.dropdown-title {
  margin: 0;
  font-size: 28px;
  font-weight: bold;
  color: #001855;
}

.dropdown-title a {
  text-decoration: none;
  color: #0046ff; /* Adjust color to match the design */
  font-weight: 600;
}

.dropdown-title a:hover {
  color: #002db3; /* Darker hover color */
}

@media (max-width: 1200px) {
  /* Adjust the dropdown container for mobile screens */
  .containerglb {
    width: 90%; /* Use 90% of the screen width */
    left: 5%; /* Center it with some margin */
    transform: none; /* Remove centering transform */
    border-radius: 10px; /* Rounded corners for better appearance */
    top: 100%; /* Dropdown below the navlink */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); /* Softer shadow */
  }

  /* Stack items vertically for mobile */
  .dropdown-row {
    flex-direction: column; /* Stack items vertically */
    gap: 5px; /* Add spacing between items */
    justify-content: flex-start; /* Align items to the start */
  }

  .dropdown-row li {
    width: 100%; /* Make each item take up full width */
    text-align: left; /* Align text to the left */
    margin: 0; /* Remove extra margin */
  }

  .dropdown-row a {
    font-size: 14px; /* Adjust font size for mobile readability */
    padding: 10px 15px; /* Add padding for touch-friendly interaction */
    display: block; /* Ensure links take up the full width */
    background-color: #ffffff; /* Optional: Background for touchable area */
    border-radius: 5px; /* Rounded corners for links */
    color: inherit; /* Maintain text color */
    text-decoration: none; /* Remove underline */
  }

  .dropdown-row a:hover {
    background-color: #f9f9f9; /* Change background color on hover */
    color: #0046ff; /* Optional: Hover text color */
  }

  /* Adjust heading for mobile */
  .dropdown-heading {
    padding: 10px; /* Reduce padding for smaller screens */
    text-align: center; /* Center-align the heading */
    border-bottom: 1px solid #ddd; /* Optional: Separator */
  }

  .dropdown-title {
    font-size: 18px; /* Smaller font size for mobile */
    font-weight: bold;
    margin: 0;
  }
}

@media (min-width: 500px) and (max-width: 1200px) {
  .flag {
    width: 10%; /* Adjust this percentage or set a fixed pixel value as needed */
  }

  .flagNavbar {
    width: 20%;
  }
}

.arrow {
  color: rgb(255, 255, 255) !important;
}

.scrollable-menu li:hover {
  background-color: #f5f5f500;
}