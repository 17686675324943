

.container {
    display: flex;
    flex-direction: column;
}

.btn-container {
    display: flex;
    gap: 5px;
    margin-left: 10px;
    background-color: rgba(255, 228, 196, 0.623);
    padding: 5px;
    border-radius: 8px;
}

.languageselector {
    padding: 3px;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: 10px;
    border-radius: 18px;
    background-color: rgba(218, 233, 233, 0.658);
    border: none;
}

.languageselector:hover {
    cursor: pointer;
}

@media (max-width: 650px) {
    .languageselector {
        width: 60px;
        margin-right: 10px;
    }
}