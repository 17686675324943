.chatbot-icon {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: fixed;
  bottom: 20px; 
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  background-color: #fff;
  z-index: 50000;
  
}

.chatbot-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
 
}

.chatbot-icon:hover {
  transform: scale(1.1);
}


.premium-popup {
    background-color: #1e1e2d;
    color: #fff;
    border-radius: 20px; /* Add border-radius here */
    padding: 20px;
  }
  
  .premium-title {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    color: #8cd7dc;
  }
  
  .premium-button {
    background-color: #ffcc00;
    color: #1e1e2d;
    font-family: 'Poppins', sans-serif;
    border-radius: 10px; /* Border-radius for the button */
    padding: 10px 20px;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .premium-popup img {
  
    border-radius: 20px; /* Add border-radius here */
    
  }
  