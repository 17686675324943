/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {

    width: 100%;
    min-height: 70vh;
    position: relative;
    padding-top: 90px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid rgba(var(--accent-color-rgb), 0.25);
}
.heroimg{
    width: 600px !important; 
}

h3{
    color: white !important;
}

.hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    object-fit: cover;
    z-index: 0;
    /* Ensure video is behind other content */
    height: 1000px;
}

.hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    z-index: 100;
    color: white;

}

.hero p {
    color: #ffffff;
    margin: 5px 0 30px 0;
    font-size: 20px;
    font-weight: 400;
    z-index: 100;

}

.hero .btn-get-started {
    color: white;
    background: #00A0E4;
    font-family: var(--heading-font);
    font-weight: bolder;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px 12px 28px;
    border-radius: 50px;
    transition: 0.5s;
    box-shadow: #00ff40c6;
    z-index: 100;
}

.hero .btn-get-started:hover {
    color: var(--contrast-color);
    background: #0004ff;
    box-shadow: #00ff4083;

}

.hero .btn-watch-video {
    font-size: 16px;
    transition: 0.5s;
    margin-left: 25px;
    color: #72afeb;
    font-weight: 600;
    z-index: 100;
}

.hero .btn-watch-video i {
    color: #00e1ff;
    font-size: 32px;
    transition: 0.3s;
    line-height: 0;
    margin-right: 8px;
    z-index: 100;
}

.hero .btn-watch-video:hover {
    color: #059652
}

.hero .btn-watch-video:hover i {
    color: rgba(var(--accent-color-rgb), 0.85);
}

.hero .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 640px) {
    .hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    .hero p {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    .hero .btn-get-started,
    .hero .btn-watch-video {
        font-size: 13px;
    }
    .settop{
        margin-top: -20%;
    }

    .hero{
        padding-top: 10px !important;
    }

    .video{
        width: 380px !important ;
    height: 250px !important;
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

/* Connex text */
.Connexcon{
    color: #00A0E4;
}

.Connexnex{
    color: #20B14A;
}

.video{
    width: 1100px;
    height: 500px;
}

@media (max-width: 768px) {
    .heroimg {
       margin-bottom: 70px;
    }
}