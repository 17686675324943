/* .meetRow {
    position: fixed;
    display: flex;
    bottom: 0vh;
    z-index: 1;
    background-color: blue;
    width: 100%;
    height: 12vh;
} */

.button-container {
    margin-top: 10px !important;
    z-index: 20 !important;
    left: 85%;
    position: fixed;
    display: flex;
    bottom: 80%;
    z-index: 1;
}

.bottom-button,
.top-button {
    position: absolute;
    width: 200px;
    height: 50px;
    border: none;
    font-size: 18px;
    cursor: pointer;
    border-radius: 10px;
}

.bottom-button {
    background-color: white;
    color: black;
    /* border: 2px solid black; */
}

.top-button {
    background-color: transparent;
    border: 2px solid rgb(255, 255, 255);
    transition: transform 0.8s ease, opacity 0.8s ease;
    width: 200px !important;
}

.top-button:hover {
    transform: scale(1.2);
    height: 60px !important;
    margin-top: -5px;
    opacity: 0;
    /* Reduce opacity to 0 on hover */ 
}



/* For tablets and small desktops between 768px and 1024px */
@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .button-container {
        left: 75%; /* Position 40% from the left */
    }
}

/* For mobile devices, less than 768px wide */
@media only screen and (max-width: 767px) {
    .button-container {
        left: 15% ; /* Position 60% from the left */
    }
}

/* For very small mobile devices, less than 480px wide */
@media only screen and (max-width: 479px) {
    .button-container {
        left: 60% !important; /* Position 80% from the left */
        
    }

    .bottom-button,
.top-button {
    position: absolute;
    width: 150px;
    height: 30px;
    border: none;
    font-size: 13px;
    cursor: pointer;
    border-radius: 10px;
    top: -26px;
}
}


/* Feedback Button Styling */
.feedback-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    color: #000000;
    border: 2px solid #00ffff;
    border-radius: 50px;
    padding: 1px 10px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    outline: none;
    height: 35px !important;
}

.feedback-button::after {
    content: '➔';
    background-color: #76b9fd;
    color: white;
    border-radius: 50%;
    padding: 10px;
    margin-left: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: #ff3b30 !important;
    height: 35px !important;
}

.feedback-button:hover {
    background-color: #ff3b30;
    color: white;
    border: #ff3b30 !important;
}

.feedback-button:hover::after {
    background-color: white;
    color: #ff3b30;
    border: #ff3b30 !important;
}


.feedbackbtnrow{
    margin-top: 10px !important;
    z-index: 20 !important;
    left: 87%;
    position: fixed;
    display: flex;
    bottom: 67%;
    z-index: 1;
}


/* For tablets and small desktops between 768px and 1024px */
@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .feedbackbtnrow {
        left: 80%; /* Move feedback button more to the left */
        bottom: 66%; /* Move feedback button further down */
    }
}

/* For mobile devices, less than 768px wide */
@media only screen and (max-width: 768px) {
    .feedbackbtnrow {
        left: 80%; /* Move feedback button even more to the left */
        bottom: 66%; /* Move feedback button further down */
    }
}

/* For very small mobile devices, less than 480px wide */
@media only screen and (max-width: 479px) {
    .feedbackbtnrow {
        left: 63%; /* Move feedback button to the far left */
        bottom: 74%; /* Move feedback button even further down */
    }

    /* Adjust feedback button size on small devices */
    .feedback-button {
        width: 120px;
        height: 30px !important;
        font-size: 12px;
    }

    .feedback-button::after {
        font-size: 14px;
        padding: 8px;
        height: 30px !important;
    }
}
